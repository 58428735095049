import React from 'react';

const NavBarPadding = ({ children }) => {
    return (
        <div className="pt-32 md:pt-40">
            {children}
        </div>
    );
};

export default NavBarPadding;