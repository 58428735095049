import React from 'react';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import ProjectCard from '../ProjectCard';

import 'swiper/css/swiper.css';
import './SwiperProject.css';

const SwiperProject = ({ projects }) => {

    // swiper config
    const params = {
        // pagination: {
        //     el: '.swiper-pagination',
        //     type: 'bullets',
        //     clickable: true
        // },
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
        }
    }


    return (
        <Swiper {...params}>
            {projects && projects.map((project, i) => {
                return (
                    <div key={`${project.name}-image-${i}`} className="w-40">
                        <Link to={`/${project.slug}`} >
                            <ProjectCard content={project} />
                        </Link>
                    </div>

                )
            })}
        </Swiper>
    )
};
export default SwiperProject;
