
import React from "react"
import { graphql } from "gatsby"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import NavBarPadding from "../components/NavBarPadding"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from '@contentful/rich-text-types';
import SwiperProject from "../components/Project/SwiperProject"



const ProjectItems = ({ data, location }) => {
  // console.log("project", data.project)
  const { seo, awards, description, name, featuredImage, category, medias } = data.project
  const plainTextContent = description
  const options_plainText = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="my-8" >{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-justify text-[#573F3F] text-sm my-4">{children}</p>
    },
    renderMark: {},
  }
  let imageUrl = featuredImage
  if (featuredImage) {
    imageUrl = `https:${featuredImage.file.url}`
  }

  return (
    <Layout pathname={location} >
      <SiteMetadata
        title={seo ? seo.seoTitle : name}
        description={seo ? seo.description : name}
        location={location.pathname}
        image={imageUrl}
        noindex={seo ? seo.hidePageFromSearchEnginesNoindex : false}
        nofollow={seo ? seo.excludeLinksFromSearchRankingsNofollow : false}

      />
      <NavBarPadding>
        <div className="min-h-screen container md:flex md:justify-between mb-12">
          <div className="md:unset md:w-1/2 mb-12 md:pr-24">
            <div className="md:sticky md:top-9">
              <p className="large mb-4 font-bold">{name}</p>
              {/*<p className="small text-xs text-bold">{data.project.client}</p>*/}
              {plainTextContent && renderRichText(plainTextContent, options_plainText)}
              <div className="my-8 lg:my-20 flex flex-wrap">
                {
                  awards && awards.length > 0 && awards.map((award, index) => {
                    const image = getImage(award.awardImage)
                    return (
                      <a key={`project-image-${index}`} className="pr-4 py-4" href={award.links}>
                        <GatsbyImage image={image} alt="awards" />
                      </a>
                    )
                  })
                }
              </div>
              <h6 className="text-gray-500">| {category.title}</h6>
            </div>
          </div>
          <div className="md:w-1/2">
            {medias.length > 0 && medias.map((media, index) => {
              if (media.file.contentType.includes('image')) {
                let projectImage = getImage(media)
                return <GatsbyImage className="mb-8" image={projectImage} alt={`${media.title}`} key={`${name}-image-${index}`} />
              } else if (media.file.contentType.includes('video')) {
                const posterImage = getImage(featuredImage).images.fallback.src
                return (
                  <video className="w-full mb-8" poster={posterImage} controls>
                    <source src={media.file.url} type="video/mp4" />
                  </video>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      </NavBarPadding>
      <div className="border-t border-black h-full py-12">
        <div className="container">
          <p className="large mb-8">RELATED PROJECT</p>
          <SwiperProject projects={data.projects.nodes} />
        </div>
      </div>
    </Layout>
  )
}

export default ProjectItems

export const query = graphql`
query ProjectBySlug($slug: String, $categorySlug: String) {
  project: contentfulProject(slug: {eq: $slug}) {
    ...ProjectCard
  }
  projects: allContentfulProject(
    filter: {slug: {ne: $slug}, category: {slug: {eq: $categorySlug}}}
    ) {
    nodes {
      ...ProjectCard
    }
  }
}`

